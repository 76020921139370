import React, { useState } from 'react'
import { IStartPageProps } from './types'
import { CategorySection } from './CategorySection'
import { NewsCardSection } from './NewsCardSection'
import { OrdersSectionContainer } from '../startPage/OrdersSectionContainer'
import { SaleProductSection } from './SaleProductSection'
import { StrengthSection } from './StrengthSection'
import { TeasersSection } from './TeasersSection'
import { useTranslation } from 'react-i18next'
import { Section } from './Section'
import { EPlacement } from '@obeta/models/lib/models/Teasers/Teasers'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SubCategoryLists } from '@obeta/components/lib/categories-dropdown/SubCategoryList'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { TeaserSectionPanel } from './components/teaserSectionPanel/TeaserSectionPanel'
import { useMonthAdverts } from '../advertisingPage/hooks/useMonthAdverts'
import { getDateNMonthsAgo } from '@obeta/utils/lib/date-helpers/getDateNMonthsAgo'
import styles from './StartPage.module.scss'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const StartPage: React.FC<IStartPageProps> = (props) => {
  const { t } = useTranslation()
  const { desktop, mobile } = useBreakpoints()

  const [dehaCatalogLevel0, setDehaCatalogLevel0] = useState<boolean>(true)
  const { obetaCategory, permissions, strengths, isLoggedIn } = props
  const strengthsTitlePosition = mobile ? 'start' : 'center'

  const currentMonthAdverts = useMonthAdverts({
    activeMonth: getDateNMonthsAgo(),
  })
  const useShowOrdersOnStartPage = useFeatureToggle('UseShowOrdersOnStartPage')

  return (
    <div className={styles['start-page-wrapper']}>
      <div className={styles['top-section-wrapper']}>
        {!mobile && <div className={styles['top-section-background']} />}
        {desktop && (
          <div className={styles['categories-list-wrapper']}>
            <SubCategoryLists
              dehaCatalogLevel0={dehaCatalogLevel0}
              setDehaCatalogLevel0={setDehaCatalogLevel0}
              categoryDivWidth={256}
              page="start-page"
            />
          </div>
        )}
        <TeasersSection maxEls={2} placement={EPlacement.Block1} />
      </div>
      {isLoggedIn ? (
        <Section
          title={t('START.NEWS')}
          content={<NewsCardSection />}
          rightAdornmentText={t(
            !mobile ? 'START.ALL_NEWS_ADORNMENT' : 'START.ALL_NEWS_MOBILE_ADORNMENT'
          )}
          rightAdornmentLinkTo={ShopRoutes.News}
        />
      ) : (
        <Section
          title={t('START.OUR_STRENGTHS')}
          content={<StrengthSection strengths={strengths} />}
          titlePosition={strengthsTitlePosition}
        />
      )}
      {permissions?.Orders_canRead && useShowOrdersOnStartPage && <OrdersSectionContainer />}
      <TeaserSectionPanel
        maxEls={5}
        placement={EPlacement.Block2}
        currentMonthAdverts={currentMonthAdverts.monthAdverts}
      />
      <Section title={t('START.PRODUCTS')} content={<SaleProductSection />} />
      <Section
        title={t('START.CATEGORIES')}
        content={<CategorySection obetaCategory={obetaCategory} />}
      />
      <TeaserSectionPanel
        maxEls={5}
        placement={EPlacement.Block3}
        currentMonthAdverts={currentMonthAdverts.monthAdverts}
      />
      {isLoggedIn && (
        <Section
          title={t('START.OUR_STRENGTHS')}
          content={<StrengthSection strengths={strengths} compact={true} />}
          titlePosition={strengthsTitlePosition}
        />
      )}
    </div>
  )
}
